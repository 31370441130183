<template>
  <ButtonTwoIcons
    v-if="leftIcon"
    id="cta-button-baner"
    :text="label"
    :href="link"
    :right-icon="rightIcon"
    :left-icon="leftIcon"
    class="cta-button-banner btn--secondary"
  />
  <ButtonIcon
    v-else
    id="cta-button-baner"
    :href="link"
    :icon="rightIcon"
    class="cta-button-banner btn--secondary"
  >
    <SvgIcon
      v-if="leftIcon"
      width="20px"
      height="20px"
      class="cta-button-banner__icon push-icon__icon--left"
      :name="leftIcon"
    />
    {{ label }}
  </ButtonIcon>
</template>

<script>
import { ButtonTwoIcons, ButtonIcon, SvgIcon } from '@unimelb/pattern-lib-vue';

export default {
  components: {
    ButtonTwoIcons,
    ButtonIcon,
    SvgIcon,
  },
  props: {
    label: {
      type: String,
      default: 'Explore Open Days 2024',
    },
    link: {
      type: String,
      default: 'https://study.unimelb.edu.au/opendays',
    },
    leftIcon: {
      type: String,
      default: '',
    },
    rightIcon: {
      type: String,
      default: 'chevron-right',
    },
  },
};
</script>

<style lang="postcss">
.cta-button-banner {
  margin-top: 1.125rem !important;
  margin-bottom: 1.125rem !important;
  margin-left: 0 !important;
  text-transform: capitalize;

  @media (--bp-wide) {
    margin-top: 0 !important;
  }

  &__icon {
    min-width: 1.25rem;
    min-height: 1.25rem;
  }

  .push-icon__icon {
    margin-left: 0.5rem;
  }
}
</style>
